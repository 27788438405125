import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Checkbox,
  Grid,
  TextField,
  Button,
  FormHelperText,
  Snackbar,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import axios from 'axios';

import { ErrorMessage, Field, Form, Formik, useField } from 'formik';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

function ErrorSnackBar(props) {
  return (
    <>
      <Snackbar
        open={props.open}
        onClose={(event, reason) => {
          props.setOpenError(false);
        }}
        {...props}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {props.message}
        </Alert>
      </Snackbar>
      <Snackbar onClose={() => props.setOpenError(false)} />
    </>
  );
}

export default function LoginForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [openError, setOpenError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object({
        email: Yup.string().email().required('Required'),
        password: Yup.string().required('Required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setLoading(true);
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}login`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: values,
        })
          .then((res) => {
            // if(res.data)
            if (res.data.auth === 'OK') {
              localStorage.setItem('logged_user', res.data.token);
              navigate('/');
            } else if (res.data.auth === 'DENIED') {
              setOpenError('Invalid username / password');
            } else if (res.data.auth === 'RESET') {
              // temp password login
              localStorage.setItem("reset_token", res.data.token)
              navigate('/reset-password', { replace: true });
            } else {
              setOpenError('something went wrong, try again later');
            }
          })
          .catch((e) => {
            setOpenError(e.response.data.error);
          })
          .finally(() => {
            setLoading(false);
          });
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Stack spacing={3}>
            <Field
              component={TextField}
              fullWidth
              name="email"
              label="Email"
              onChange={(e) => setFieldValue('email', e.target.value)}
              variant="outlined"
              defaultValue={values.email}
              type="email"
            />
            <ErrorMessage render={(msg) => <FormHelperText error>{msg}</FormHelperText>} name="email" />
            <Field
              component={TextField}
              fullWidth
              onChange={(e) => setFieldValue('password', e.target.value)}
              onKeyUp={(e) => console.log(e)}
              defaultValue={values.password}
              name="password"
              label="password"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ErrorMessage render={(msg) => <FormHelperText error>{msg}</FormHelperText>} name="description" />
          </Stack>

          <LoadingButton loading={loading} sx={{ mt: 3 }} fullWidth size="large" type="submit" variant="contained">
            Login
          </LoadingButton>
          <ErrorSnackBar
            message={openError}
            open={typeof openError === 'string'}
            setOpenError={setOpenError}
            severity="error"
          />
        </Form>
      )}
    </Formik>
  );
}
