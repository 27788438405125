import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {

  const getLogoSrc = () => {
    if (process.env.REACT_APP_ISDEV === "DEV") {
      return "https://firebasestorage.googleapis.com/v0/b/kudukey.appspot.com/o/kudukey_dev.jpeg?alt=media&token=a6df14f4-172f-4d0f-a6c8-fa304c80c302";
    } 
    return "https://www.kudukey.com/wp-content/uploads/2016/03/Logo-PNG-small-2.png";
  };

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 200,
        height: 60,
        display: 'inline-flex',
        cursor: 'pointer',
        ...sx,
      }}
      {...other}
    >
      <img src ={getLogoSrc()} alt="" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
