import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
// import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ResetPage from './pages/ResetPage';

const DashboardLayout = lazy(() => import('./layouts/dashboard'));

// const EventGroups = lazy(() => import('./event-groups/EventGroups'));
const Events = lazy(() => import('./events/Events'));
const NewEvent = lazy(() => import('./events/NewEvent'));
const ViewEvent = lazy(() => import('./events/ViewEvent'));

const ViewEventGroup = lazy(() => import('./events/ViewEventGroup'));

const UploadStockCSV = lazy(() => import('./events/UploadStockCSV'));

const Locations = lazy(() => import('./locations/Locations'));

// const NewEvent2 = lazy(() => import('./events/NewEvent'));

// const AddEventGroup = lazy(() => import('./event-groups/AddEventGroup'));

// const Buttons = lazy(() => import('../app_bkp/basic-ui/Buttons'));
// const Dropdowns = lazy(() => import('../app_bkp/basic-ui/Dropdowns'));

// const BasicElements = lazy(() => import('../app_bkp/form-elements/BasicElements'));

// const BasicTable = lazy(() => import('../app_bkp/tables/BasicTable'));

// const Mdi = lazy(() => import('../app_bkp/icons/Mdi'));

// const ChartJs = lazy(() => import('../app_bkp/charts/ChartJs'));

// const Error404 = lazy(() => import('../app_bkp/error-pages/Error404'));
// const Error500 = lazy(() => import('../app_bkp/error-pages/Error500'));

// const Login = lazy(() => import('./user-pages/Login'));
// const Register1 = lazy(() => import('../app_bkp/user-pages/Register'));

// const AddEvent = lazy(() => import('./event-groups/AddEvent'));
// const AddEventStations = lazy(() => import('./event-groups/AddEventStations'));
// const ViewEventTickets = lazy(() => import('./event-groups/ViewEventTickets'));
// const ViewEventStations = lazy(() => import('./event-groups/ViewEventStations'));

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Events />, index: true },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'events-groups', element: <Events /> },
        { path: 'events-groups/:id', element: <ViewEventGroup /> },
        // { path: 'events-groups/:id/', element: <ViewEventGroup /> },
        { path: 'events', element: <Events /> },
        // ViewEventOperators
        { path: 'events/create', element: <NewEvent /> },
        { path: 'events/:id/create', element: <NewEvent /> },
        { path: 'events/:eventgroup', element: <ViewEvent /> },
        { path: 'events/:eventgroup/:id', element: <ViewEvent /> },
        { path: 'events/:eventgroup/:id/:tab', element: <ViewEvent /> },
        { path: 'upload-stock/:event_id/:station_id/', element: <UploadStockCSV /> },
        { path: 'locations', element: <Locations /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'reset-password',
      element: <ResetPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
